import React, { useState, useEffect, useRef } from 'react';

const Tooltip = ({ tooltipText }) => {
    const [visible, setVisible] = useState(false);
    const tooltipRef = useRef(null);

    // Toggle visibility on click (for mobile)
    const handleTooltipClick = () => {
        setVisible(!visible);
    };

    const formattedText = tooltipText.replace(/\n/g, '<br>');

    // Hide the tooltip if clicked outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
                setVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="tooltip-container" onClick={handleTooltipClick}>
            <span className="tooltip-icon">ℹ️</span>
            <div className={`tooltip-text ${visible ? 'visible' : ''}`} dangerouslySetInnerHTML={{ __html: formattedText }}></div>
        </div>
    );
};

export default Tooltip;