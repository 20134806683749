// src/App.js
import React, { useState } from 'react';
import './App.css';

// Inputs
import BenefitRates         from './components/inputs/BenefitRates';
import HospitalizationCosts from './components/inputs/HospitalizationCosts';
import Margin               from './components/inputs/Margin';
import NonLaborCosts        from './components/inputs/NonLaborCosts';
import PanelSizeBaseline    from './components/inputs/PanelSizeBaseline';
import PanelSizeProactive   from './components/inputs/PanelSizeProactive';
import PatientAgeMix        from './components/inputs/PatientAgeMix';
import TeamBaseline         from './components/inputs/TeamBaseline';
import TeamProactive        from './components/inputs/TeamProactive';

// Outputs
import Chart                from './components/outputs/Chart';
import BreakEven            from './components/outputs/BreakEven';
import Results              from './components/outputs/Results';

// Hospital Spend Worksheet (DEBUG)
// import HospitalSpend     from './components/hospital_spend/HospitalSpend';
// import panelSizeBaseline from "./components/inputs/PanelSizeBaseline";


function App() {

  // set to true to show debugging in the UI
  // true will also display variable names instead of the human labels.
  window.DEBUG = (process.env.NODE_ENV === 'test') || false

  // Inputs With Defaults
  const [inputs, setInputs] = useState({


    // Current Team (BaseLine)

    // PanelSizeBaseline.js
    panelSizeBaseline: 2700,

    // TeamBaseline.js
    familyMedicineDoctorBaseline: 1,
    npPaBaseline: 0.5,
    medicalAssistantBaseline: 2,
    athleticTrainerBaseline: 0,
    rnBaseline: 0.5,
    pharmacistBaseline: 0,
    healthCareSocialWorkerBaseline: 0.25,
    mentalHealthProfessionalBaseline: 0.25,
    clericalStaffBaseline: 1,
    communityHealthWorkerBaseline: 0,

    //Proactive Team

    // PanelSizeProactive.js
    panelSizeProactive: 2700,
    hospitalCostOffset: 25,

    // TeamProactive
    familyMedicineDoctorProactive: 1,
    npPaProactive: 1,
    medicalAssistantProactive: 4,
    athleticTrainerProactive: 1,
    rnProactive: 2,
    pharmacistProactive: 1,
    healthCareSocialWorkerProactive: 2,
    mentalHealthProfessionalProactive: 1,
    clericalStaffProactive: 2,
    communityHealthWorkerProactive: 3,
    benefitRatesProactive: 0.5,

    //Patient Severity

    // HospitalizationCosts.js
    hospitalizationCosts: 0,

    // PatientAgeMix.js
    age0_18: 25,                    // 25.368
    age19_44: 31,                   // 31.306
    age45_64: 27,                   // 26.883
    age65_plus: 17,                 // 16.441    <-- round to 17 so the sum is 100?

    //Financial Information

    // BenefitRates.js
    benefitRates: 50,

    // Margin.js
    margin: 3.0,

    // NonLaborCosts.js
    // facilitiesCosts: 100000,
    costPerPatient: 21,


    // Input: Salaries
    familyMedicineDoctorSalary: 226500,
    npPaSalary: 131370,
    medicalAssistantSalary: 46530,
    athleticTrainerSalary: 64460,
    rnSalary: 98910,
    pharmacistSalary: 148610,
    healthCareSocialWorkerSalary: 76430,
    mentalHealthProfessionalSalary: 65740,
    clericalStaffSalary: 46340,
    communityHealthWorkerSalary: 46800,
  });

  // Static Values used in Output Calculations

  const [fixed, setFixed] = useState({

    // HospitalSpend.js
    hospitalCost0_18:     685.5533,
    hospitalCost19_44:    1324.44,
    hospitalCost45_64:    2335.109,
    hospitalCost65_plus:  3812.974,
    hospitalCostAll:      1843.22,
    mepsFactor:           0.171659381,

  })


  // Output Calculations
  const calculateOutputs = (inputs) => {

    const laborCostBaseline      =
      (
        inputs.familyMedicineDoctorBaseline     * inputs.familyMedicineDoctorSalary +
        inputs.npPaBaseline                     * inputs.npPaSalary +
        inputs.medicalAssistantBaseline         * inputs.medicalAssistantSalary +
        inputs.athleticTrainerBaseline          * inputs.athleticTrainerSalary +
        inputs.rnBaseline                       * inputs.rnSalary +
        inputs.pharmacistBaseline               * inputs.pharmacistSalary +
        inputs.healthCareSocialWorkerBaseline   * inputs.healthCareSocialWorkerSalary +
        inputs.mentalHealthProfessionalBaseline * inputs.mentalHealthProfessionalSalary +
        inputs.clericalStaffBaseline            * inputs.clericalStaffSalary +
        inputs.communityHealthWorkerBaseline    * inputs.communityHealthWorkerSalary
      ) * (1 + (inputs.benefitRates / 100))


    const laborCostProactive      =
      (
        inputs.familyMedicineDoctorProactive     * inputs.familyMedicineDoctorSalary +
        inputs.npPaProactive                     * inputs.npPaSalary +
        inputs.medicalAssistantProactive         * inputs.medicalAssistantSalary +
        inputs.athleticTrainerProactive          * inputs.athleticTrainerSalary +
        inputs.rnProactive                       * inputs.rnSalary +
        inputs.pharmacistProactive               * inputs.pharmacistSalary +
        inputs.healthCareSocialWorkerProactive   * inputs.healthCareSocialWorkerSalary +
        inputs.mentalHealthProfessionalProactive * inputs.mentalHealthProfessionalSalary +
        inputs.clericalStaffProactive            * inputs.clericalStaffSalary +
        inputs.communityHealthWorkerProactive    * inputs.communityHealthWorkerSalary
      ) * (1 + (inputs.benefitRates / 100))


    const variableCostsBaseline   = inputs.costPerPatient * inputs.panelSizeBaseline
    const variableCostsProactive  = inputs.costPerPatient * inputs.panelSizeProactive

    // const totalCostBaseline       = laborCostBaseline + inputs.facilitiesCosts + variableCostsBaseline;
    const totalCostBaseline       = laborCostBaseline + variableCostsBaseline;
    // const totalCostProactive      = laborCostProactive + inputs.facilitiesCosts + variableCostsProactive;
    const totalCostProactive      = laborCostProactive + variableCostsProactive;

    const capRateBaseline         = (totalCostBaseline / ((1 - inputs.margin / 100) * inputs.panelSizeBaseline) / 12);
    const capRateProactive        = (totalCostProactive / ((1 - inputs.margin / 100) * inputs.panelSizeProactive) / 12);
    const capRateDifference       = (capRateProactive - capRateBaseline)

      // =IF(hosp_cost_input=0,C18,hosp_cost_input)
    const hospitalSpendBaseline   = inputs.hospitalizationCosts === 0 ? (inputs.age0_18 * fixed.hospitalCost0_18 + inputs.age19_44 * fixed.hospitalCost19_44 + inputs.age45_64 * fixed.hospitalCost45_64 + inputs.age65_plus * fixed.hospitalCost65_plus) / (1200) * (1 + fixed.mepsFactor) : inputs.hospitalizationCosts
    const hospitalSpendProactive  = (hospitalSpendBaseline * (1 - inputs.hospitalCostOffset / 100))
    const hospitalSpendDifference = (hospitalSpendProactive - hospitalSpendBaseline)

    const combinedSpendBaseline   = (capRateBaseline + hospitalSpendBaseline)
    const combinedSpendProactive  = (capRateProactive + hospitalSpendProactive)
    const combinedSpendDifference = (combinedSpendProactive - combinedSpendBaseline)

    const panelSizeDifference     = inputs.panelSizeProactive - inputs.panelSizeBaseline

    const breakEvenOffset         = capRateDifference / hospitalSpendBaseline * 100
    // const breakEvenPanelSize      = inputs.panelSizeBaseline * ( laborCostProactive + inputs.facilitiesCosts ) / (laborCostBaseline + inputs.facilitiesCosts)
    const breakEvenPanelSize      = inputs.panelSizeBaseline * laborCostProactive  / laborCostBaseline


    return {
      laborCostBaseline,
      laborCostProactive,
      variableCostsBaseline,
      variableCostsProactive,
      totalCostBaseline,
      totalCostProactive,
      capRateBaseline,
      capRateProactive,
      capRateDifference,
      hospitalSpendBaseline,
      hospitalSpendProactive,
      hospitalSpendDifference,
      combinedSpendBaseline,
      combinedSpendProactive,
      combinedSpendDifference,
      panelSizeDifference,
      breakEvenOffset,
      breakEvenPanelSize,
    };
  };

  const outputs = calculateOutputs(inputs);

  // State for collapsible sections
  const [expandedSections, setExpandedSections] = useState({
    chart: false,
  });

  // Toggle function for collapsible sections
  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const [activeTab, setActiveTab] = useState(0);

  // Labels for each tab
  const tabs = [
    'Current Team',
    'Proactive Team',
    'Patient Severity',
    'Financial Information',
  ];

  // Rendering content based on the active tab
  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return (
            <>
              <div className="panel"><p>Enter the target panel size for a PCP, and indicate the total FTE of clinicians and staff of each type</p></div>
                <PanelSizeBaseline inputs={inputs} setInputs={setInputs} />
                <TeamBaseline inputs={inputs} setInputs={setInputs} />
            </>
        );
      case 1:
        return (
            <>
              <div className="panel"><p>Enter the anticipated hospital+ED spending offset from moving to the alternative team, the anticipated
                new panel size, and the total FTE of clinicians and staff of each type in the alternative team</p></div>
              <PanelSizeProactive inputs={inputs} setInputs={setInputs} />
              <TeamProactive inputs={inputs} setInputs={setInputs} />
            </>
        );

      case 2:
        return (
            <>
              <div className="panel"><p>Provide information on <b>EITHER</b> hospital and ED spending under the baseline team (if known), or
                the age distribution of patients in the typical patients</p></div>
              <HospitalizationCosts inputs={inputs} setInputs={setInputs} />
              <PatientAgeMix inputs={inputs} setInputs={setInputs} />
            </>
        );
      case 3:
        return (
            <>
              <div className="panel"><p>Enter some additional financial information about the primary care practice if you want to change
                from the defaults to make the calculations more specific to your context</p></div>
              <NonLaborCosts inputs={inputs} setInputs={setInputs} />
              <Margin inputs={inputs} setInputs={setInputs} />
              <BenefitRates inputs={inputs} setInputs={setInputs} />
            </>
        );

      default:
        return null;
    }
  };

  // Handlers for the Next and Previous buttons
  const handleNext = () => {
    if (activeTab < tabs.length - 1) {
      setActiveTab(activeTab + 1);
    }
  };

  const handlePrevious = () => {
    if (activeTab > 0) {
      setActiveTab(activeTab - 1);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <div>
          <h1>Financial Summary</h1>
        </div>

        <div>
          <Results inputs={inputs} outputs={outputs} />
          <BreakEven outputs={outputs} />
          <div className="expandable-section">
            <h3 onClick={() => toggleSection('chart')}
                className={`expand-collapse-icon ${expandedSections.chart ? "expanded" : "collapsed"}`}>
              Graph {expandedSections.chart ? "▼" : "►"}
            </h3>
            {expandedSections.chart && (
                <div className="section-content">
                  {expandedSections.chart && <Chart inputs={inputs} outputs={outputs} />}
                </div>
            )}
          </div>
        </div>
        <br/>
      </header>

      <main className="main-content">

        <div className="tab-layout">
          <div className="tabs">
            {tabs.map((tab, index) => (
                <button
                    key={index}
                    onClick={() => setActiveTab(index)}
                    className={activeTab === index ? 'active' : ''}
                >
                  {tab}
                </button>
            ))}
          </div>

          <div className="tab-content-with-arrows">
            <button
                onClick={handlePrevious}
                className="arrow-left"
                disabled={activeTab === 0}
            >
              &#8249; {/* Left arrow */}
            </button>

            <div className="tab-content">
              {renderTabContent()}
            </div>

            <button
                onClick={handleNext}
                className="arrow-right"
                disabled={activeTab === tabs.length - 1}
            >
              &#8250; {/* Right arrow */}
            </button>
          </div>

        </div>
        <br/>

        {/*<h3>Hospital Spend Worksheet (DEBUG)</h3>*/}
        {/*<HospitalSpend inputs={inputs} setInputs={setInputs} fixed={fixed} setFixed={setFixed}  />*/}


        {/*<CurrentTeam inputs={inputs} setInputs={setInputs} />*/}
        {/*<FutureTeam inputs={inputs} setInputs={setInputs} />*/}

        {/*<Results inputs={inputs} outputs={outputs} />*/}
        {/*<BreakEven inputs={inputs} outputs={outputs} />*/}
      </main>
      <footer className="footer">
        <p>Copyright © 2024, UW-Madison</p>
      </footer>
    </div>


  );
}

export default App;
